/* roboto-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/roboto-v30-latin-100.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-100.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('fonts/roboto-v30-latin-100italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-100italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-100italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/roboto-v30-latin-300.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-300.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/roboto-v30-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-300italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-300italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/roboto-v30-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/roboto-v30-latin-italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/roboto-v30-latin-500.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-500.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/roboto-v30-latin-500italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-500italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-500italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/roboto-v30-latin-700.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-700.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/roboto-v30-latin-700italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-700italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/roboto-v30-latin-900.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-900.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-900italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/roboto-v30-latin-900italic.eot');
  /* IE9 Compat Modes */
  src: url('fonts/roboto-v30-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/roboto-v30-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/roboto-v30-latin-900italic.woff') format('woff'), /* Modern Browsers */ url('fonts/roboto-v30-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/roboto-v30-latin-900italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
html {
  font-family: 'Roboto';
}
#alerts {
  position: absolute;
  top: 38px;
  width: auto;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0);
}
.MuiAlert-root {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important;
}
.MuiDataGrid-columnHeaders,
.MuiDataGrid-footerContainer {
  background-color: #fafafa;
}
.MuiFormControl-root {
  margin-bottom: 2rem !important;
}
.MuiInputBase-root {
  background-color: transparent !important;
}
.MuiTextField-root,
.MuiTextField-root:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.backbutton {
  margin-left: 1rem !important;
}
.MuiMenu-list a {
  text-decoration: none;
  color: inherit;
}
.MuiAutocomplete-tag {
  margin: 15px 3px !important;
}
.css-turniq.Mui-selected,
.css-turniq:hover {
  color: #fff;
}
.css-turniq .MuiChip-deleteIcon:hover {
  color: inherit !important;
}
.MuiSelect-select {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.MuiSelect-icon {
  color: #d9d9d9 !important;
}
.selectField:hover {
  background-color: rgba(25, 118, 210, 0.08) !important;
}
.show label.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
}
.show input.Mui-disabled,
.show textarea.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400;
}
.reference-link {
  padding: 16px 0 0 13px;
}
.reference-link a {
  text-decoration: none;
  color: #3d5476;
  font-weight: 500;
}
.reference-link-mobilheime {
  padding-right: 13px;
}
.reference-link-mobilheime a {
  text-decoration: none;
  color: #3d5476;
  font-weight: 500;
}
.link-button-container {
  position: relative;
  margin-bottom: 1.5rem;
  margin-top: -1rem;
  height: 2.5rem;
}
.link-button-container .link-buttons {
  position: absolute;
  right: 0;
}
.link-button-container a {
  margin-left: 1rem !important;
}
nav.MuiList-root a {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
#root main .main {
  height: 100%;
}
#root main .main iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.MuiTabs-flexContainer .Mui-selected {
  background: none !important;
}
